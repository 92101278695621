.nav-container-main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100%;
  background-color: var(--td-primary);
}
.nav-container-main > div {
  padding: 45px 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav-container-main > div h1 {
  font-size: 1.4rem;
  font-weight: bolder;
  letter-spacing: 0.2rem;
  margin-bottom: 1rem;
  color: var(--td-white);
}
.nav-container-main > div span {
  color: var(--td-dark-03);
  text-align: center;
  font-size: 0.7rem;
}
nav.menu-app {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  /* border: 1px solid red; */
}
nav.menu-app ul {
  padding: 0px;
}
nav.menu-app ul.first-level {
  flex: 1;
}
nav.menu-app ul.second-level {
  padding-bottom: 1rem;
}
nav.menu-app ul li {
  display: flex;
  align-items: center;
  margin-right: 10px;
  /* border: 1px solid red; */
}

nav.menu-app ul li a {
  padding: 20px 15px 20px 30px;
  text-decoration: none;
  width: 100%;
  color: var(--td-white);
  display: flex;
  justify-items: center;
  transition: all 0.5s;
}
nav.menu-app ul li a span {
  margin-right: 0.8rem;
}
nav.menu-app ul li:hover {
}
nav.menu-app ul li a:hover,
nav.menu-app ul li a.active {
  background-color: var(--td-teal);
  /* border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem; */
  transition: all 0.5s;
}

hr {
  width: 200px;
}

/* ##################################### */
/* ##################################### */
/* ##################################### */
/* ######## NAVBAR STYLE V1 ############ */
/* ##################################### */
/* ##################################### */
/* ##################################### */

.v1 hr {
  display: none;
}

.v1 .nav-container-main > div {
  height: 120px;
  background-color: var(--td-success);
  margin-bottom: 10px;
  border-bottom: 10px solid var(--td-white);
}
.v1 nav.nav.menu-app ul li {
  width: 95%;
  margin: 0 auto;
  margin-top: 5px;
}
.v1 nav.nav.menu-app ul li a:hover,
.v1 nav.nav.menu-app ul li a.active {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-radius: var(--td-border-radius);
}
