.tables {
  /* border: 1px solid red; */
  width: 100%;
  /* margin: 0 auto; */
}
.tables .table-header {
  display: flex;
  color: rgba(0, 0, 0, 0.441);
}

.tables .table-header > div {
  /* flex: 1; */
  padding: 25px 10px;
  margin-bottom: 5px;

  background-color: var(--td-white);
  box-shadow: var(--td-box-shadow);
  border-left: 8px solid var(--td-secondary);
}
.tables .table-header > div:nth-child(1) {
  border-radius: var(--td-border-radius);
}

.tables .table-content {
  display: flex;
}

.tables .table-content > div {
  /* flex: 1; */
  padding: 17px 10px;
  margin-bottom: 2px;
  background-color: var(--td-white);
  box-shadow: var(--td-box-shadow);
  border-left: 8px solid var(--td-primary);
}
.tables .table-content > div:nth-child(1) {
  border-radius: var(--td-border-radius);
}
.loaderss {
  position: relative;
  margin-top: 80px;
  /* border: 1px solid red; */
}

.table-content .actions,
.table-normal .actions {
  display: flex;
}
.table-content .btn,
.table-normal .btn {
  padding: 8px;
  margin-right: 5px;
  border-radius: var(--td-border-radius);
  text-align: center;
  cursor: pointer;
  align-self: flex-start;
}
.table-content .btn.success,
.table-normal .btn.success {
  background-color: var(--td-success);
  border: 1px solid var(--td-success);
}
.table-content .btn.danger,
.table-normal .btn.danger {
  background-color: var(--td-white);
  border: 1px solid var(--td-red);
}
.table-content .btn.danger img,
.table-normal .btn.danger img {
  width: 14px;
  height: 14px;
}
.table-content .btn.danger,
.table-normal .btn.info {
  background-color: var(--td-info);
  border: 1px solid var(--td-info);
}

/* TAILLE COLUMN */
.tables .column-01 {
  flex: 1;
}
.tables .column-02 {
  flex: 2;
}
.tables .column-03 {
  flex: 3;
}
.tables .column-04 {
  flex: 4;
}
.tables .column-05 {
  flex: 5;
}

/* 
############ 
############
############
          STYLE NORMAL TABLES 
############
############
############
############
*/
.table-normal {
  width: 100%;
}
.table-normal thead {
  background-color: var(--td-primary);
}
.table-normal thead tr td {
  padding: 20px 10px;
  font-size: 1rem;
  font-weight: bolder;
  color: var(--td-white);
}
/* .table-normal tbody {
}
.table-normal tbody tr {
} */
.table-normal tbody tr:nth-child(even) {
  background-color: #e1e4e9;
}
/* .table-normal tbody tr:nth-child(odd) {
  
} */
.table-normal tbody tr td {
  padding: 0.8rem 10px;
  font-size: 1rem;
}
.table-normal .figure-div {
}
.table-normal figure {
  display: flex;
  padding: 0;
  margin: 0;
}
.table-normal figure.px-50 {
  width: 40px;
  max-height: 40px !important;
}
.table-normal figure img {
  width: 100%;
}
