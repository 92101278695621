.footer-site {
  border-top: 1rem solid var(--td-secondary);
}
.footer-site .foot_content {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
}
.footer-site p {
  font-size: 0.9rem;
}
.footer-site .logo {
  flex: 1;
  padding-right: 2rem;
  display: flex;
  align-items: flex-start;
}
.footer-site .logo figure {
  width: 8rem;
  margin-right: 1rem;
  /* margin-bottom: 2rem; */
}
.footer-site .footer_menu {
  flex: 1;
  display: none;
}
/* .footer-site .footer_menu nav {
}
.footer-site .footer_menu nav ul {
}
.footer-site .footer_menu nav ul li {
} */
.footer_menu .ant-anchor-link {
  padding: 0px !important;
}
.footer-site .footer_menu nav ul li a,
.footer-site .footer_menu nav ul li .site-link a {
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: bolder;
  transition: all 1s;
  font-size: 0.8rem;
  margin-left: 0;
  color: var(--td-dark);
}
.footer-site .footer_menu nav ul li a {
}
.footer-site .footer_menu nav ul li a:hover {
  color: var(--td-dark-02);
  transition: all 1s;
  /* font-size: 0.98rem; */
}
.footer-site .footer_contact {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-site .footer_contact .contact-item {
  display: flex;
  align-items: center;
}
.footer-site .footer_contact .contact-item a {
  line-height: 2rem;
  font-size: 0.9rem;
  margin-left: 0.5rem;
}

.footer-site .footer_addresse {
  flex: 1;
  margin-top: 1rem;
  text-align: center;
  /* display: flex; */
}
.footer-site .footer_addresse .addresse {
  margin-bottom: 1rem;
}
.footer-site .footer_addresse .addresse p {
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 2rem; */
}
.footer-site .footer_addresse .addresse p span {
  margin-left: 0.5rem;
}
.footer-site .droit-auteur {
  text-align: center;
  padding: 1rem;
  color: var(--td-white);
  /* margin-top: 1rem; */
  background-color: var(--td-secondary);
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
  .footer-site {
  }
  .footer-site .footer_menu {
    display: block;
  }
  .footer-site .foot_content {
    flex-direction: row;
  }
  .footer-site .footer_addresse {
    margin-top: 0rem;
  }
  .footer-site .footer_contact {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer-site .footer_addresse {
    text-align: left;
    /* display: flex; */
  }
  .footer-site .footer_addresse .addresse p {
    justify-content: flex-start;
  }
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
