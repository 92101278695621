.realisations {
  display: flex;
  flex-direction: column;
  width: 85vw;
  margin: 0 auto;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.realisations .title {
  margin: 0 auto;
  margin-bottom: 4rem;
}
.realisations .realisation_image {
  height: 25vh;
  margin: 0.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  /* border-bottom: 0.4rem solid var(--td-primary); */
  /* border-top: 0.4rem solid var(--td-secondary); */
  position: relative;

  border-radius: 0.8rem;
}
/* .realisations .realisation_image figure {
  height: auto;
} */
.realisations .realisation_image .realisation-bg-color {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.47);

  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0 3rem;
}
.realisations .realisation_image .realisation-bg-color p {
  color: var(--td-white);
  font-weight: bolder;
  text-transform: uppercase;
  text-align: center;
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
  .realisations {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }
  .realisations .realisation_image {
    height: 18vw;
    /* margin: 0 0.5rem; */
  }
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
