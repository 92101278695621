.header-site {
  display: flex;
  flex-direction: column;
  padding: 1rem 0px;
  box-shadow: var(--shadow-1);
}
.header-site .logo {
  flex: 2;
  display: flex;
  justify-content: center;
  /* background-color: red; */
  /* align-items: center; */
}
.header-site .logo figure {
  width: 4rem;
  /* background-color: black; */
}
.header-site .logo .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 1rem;
  /* background-color: red; */
}
.header-site .logo .title span:nth-child(1) {
  font-size: 1.8rem;
  color: var(--td-primary);
}
.header-site .logo .title span:nth-child(2) {
  color: var(--td-dark-03);
  font-size: 0.8rem;
}
.header-site .address {
  flex: 1;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.8rem 0px;
}
.header-site .address div {
  text-align: center;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header-site .address div p {
  line-height: 1.5rem;
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
  .header-site {
    flex-direction: row;
  }
  .header-site .logo {
    flex: 2;
    display: flex;
    justify-content: center;
  }
  .header-site .address {
    display: flex;
    flex: 1.5;
    display: flex;
    margin: 0rem 0px;
  }
  .header-site .address div {
    text-align: center;
  }
  .header-site .logo .title span:nth-child(1) {
    font-size: 2.4rem;
  }
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
