.form {
  /* border: 1px solid green; */
  width: 100% !important;
  /* display: flex;
  width: 100%; */
  /* width: 100%; */
}
.form.row {
  display: flex;
  flex-direction: row;
}
.form.row > div {
  flex: 1;
  margin-right: 10px;
}
.form.row > div:not(:nth-child(1)) {
}
.form.column {
  display: flex;
  flex-direction: column;
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
