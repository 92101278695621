.pourquoi-nous {
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin: 0 auto;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.pourquoi-nous .title {
  margin: 0 auto;
  margin-bottom: 4rem;
}
.pourquoi-nous .pourquoi_card {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-3);
  padding: 1rem;
  /* border-radius: 0.4rem; */
  overflow: hidden;
  border-top: 0.4rem solid var(--td-primary);
}
.pourquoi-nous .pourquoi_card-icon {
  margin-bottom: 2rem;
}
/* .pourquoi-nous .pourquoi_card-title {
}
.pourquoi-nous .pourquoi_card-description {
} */
