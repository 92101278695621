.form-postuler {
  /* justify-content: space-between; */
  /* border: 1px solid red; */
}
.p-expirer {
  color: var(--td-red);
  text-align: center;
  font-weight: bolder;
}

.form-postuler > div {
  width: 100%;
}

/* MEDIA QUERY */
@media (min-width: 576px) {
  .form-postuler > div {
  }
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
  .form-postuler {
    display: flex;
    justify-content: space-between;
  }
  .form-postuler > div {
    width: 49%;
  }
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
