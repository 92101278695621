.header-pager {
  display: flex;
  /* border: 1px solid red; */
  padding: 0.8rem 0px;
}
.header-pager div:nth-child(1) {
  flex: 1;
}
.header-pager div:nth-child(1) h2 {
  font-weight: bolder !important;
  font-size: 1.2rem;
  color: var(--td-gray);
  text-transform: uppercase;
}
/* .header-pager div:nth-child(2) {
} */
.header-pager div:nth-child(2) .btn-add {
  text-decoration: none;
  background-color: var(--td-success);
  padding: 0.8rem 1.6rem;
  color: var(--td-white);
  border: var(--td-border-radius);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: var(--td-border-radius);
}
.header-pager div:nth-child(2) .btn-add span {
  margin-left: 5px;
}
