.partenaires {
  display: flex;
  flex-direction: column;
  width: 85vw;
  margin: 0 auto;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.partenaires .title {
  margin: 0 auto;
  margin-bottom: 4rem;
}

.partenaires .bloc-content_partenaire {
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.partenaires .partenaire_image {
}
.partenaires .partenaire_image figure {
  width: 120px;
  padding: 10px;
  border-radius: 3px;
}
.partenaires .partenaire_image figure img {
}
