.skeleton {
}
.skeleton div {
}
.skeleton p {
}
.skeleton h1:empty,
.skeleton h2:empty,
.skeleton h3:empty,
.skeleton h4:empty,
.skeleton h5:empty,
.skeleton h6:empty,
.skeleton p:empty,
.skeleton span:empty {
  width: 120px;
  height: 15px;
  background-color: rgb(207, 205, 205);
  /* margin: 0 auto; */
  animation: mymove 1s linear infinite;
  border-radius: 30px;
}

.skeleton .sk-btn {
  height: 25px !important;
  background-color: rgb(207, 205, 205);
  animation: mymove 1s linear infinite;
}

/* SKELETON POUR MES BOUTONS */

.skeleton button {
  background-color: rgb(207, 205, 205);
  animation: mymove 1s linear infinite;
}
.skeleton button span {
  opacity: 0;
}
@keyframes mymove {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #c7c3c3c7;
  }
  100% {
    background-color: #eee;
  }
}
