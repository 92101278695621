.dropdown {
  border: 1px solid red;
  position: relative;
  width: 100%;
  border: 0px;
  /* z-index: 100; */
}
.dropdown ul {
  position: absolute;
  right: 0;
  background-color: var(--td-white);
  z-index: 1;
  box-shadow: var(--td-box-shadow);
  white-space: nowrap;
  transition: all 1s;
  text-align: left;
}
.dropdown ul li {
  padding: 1rem 4rem 1rem 1rem;
  display: flex;
  align-items: center;
}
.dropdown ul li:hover {
  background-color: #ccc9cc4c;
}
.dropdown ul li span {
  margin-left: 0.4rem;
  font-size: 1rem;
}
.dropdown ul.visible {
  /* height: 100%; */
  max-height: auto;
  overflow: hidden;
  transition: 1 all;
}
.dropdown ul.invisible {
  height: 0px;
  overflow: hidden;
  transition: all 1s;
}
