.loader {
  position: absolute !important;
  top: calc(50% - 25px);
  left: calc(50% - 25px);

  width: 50px;
  height: 50px;
  border: 16px solid #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;

  border-left-color: var(--td-primary);
  border-right-color: var(--td-primary);
  border-radius: 100px;
  /* padding: 20px; */

  animation: loader- 1s linear infinite;
}

.loader span {
  font-size: 0.8rem;
  color: var(--td-success);
}

.loader.min {
  position: relative !important;
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  border: 4px solid transparent;
  border-right-color: var(--td-white);
  border-bottom-color: var(--td-white);

  /* border: 1px solid red; */
}
.loader.big {
  position: relative !important;
  width: 40px;
  height: 40px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  border: 4px solid transparent;
  border-right-color: var(--td-white);
  border-bottom-color: var(--td-white);

  /* border: 1px solid red; */
}

.loader.min01 {
  width: 40px;
  height: 40px;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  border: 8px solid transparent;
  border-right-color: var(--td-primary);
  border-bottom-color: var(--td-primary);
}

.loader.primary {
  border-right-color: var(--td-primary);
  border-bottom-color: var(--td-primary);
}

@keyframes loader- {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
