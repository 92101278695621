@font-face {
  font-family: "ChevinLight";
  src: url("fonts/Roboto/ChevinLight.otf");
}

@font-face {
  font-family: "rubik";
  src: url("fonts/Rubik/static/Rubik-Regular.ttf");
}
@font-face {
  font-family: "rubik-gras";
  src: url("fonts/Rubik/static/Rubik-Bold.ttf");
}
@font-face {
  font-family: "rubik-lighter";
  src: url("fonts/Rubik/static/Rubik-Light.ttf");
}
@font-face {
  font-family: "rubik-extra-gras";
  src: url("fonts/Rubik/static/Rubik-ExtraBold.ttf");
}

@font-face {
  font-family: "Lobster-gras";
  src: url("fonts/Lobster/Lobster-Regular.ttf");
}
@font-face {
  font-family: "Anton";
  src: url("fonts/Anton/Anton-Regular.ttf");
}

:root {
  --td-blue: #1d4ed8;
  --td-indigo: #4338ca;
  --td-purple: #7e22ce;
  --td-pink: #be185d;
  --td-red: #b91c1c;
  --td-orange: #b91c1c;
  --td-yellow: #a16207;
  --td-green: #15803d;
  --td-teal: #0f766e;
  --td-cyan: #0e7490;
  --td-gray: #3f3f46;
  --td-white: #ffffff;
  --td-black: #000000;
  --td-gray-dark: #0000001b;
  --td-primary: #243b4a;
  --td-secondary: #023047;
  --td-success: #047857;
  --td-info: #0369a1;
  --td-warning: #c2410c;
  --td-danger: #be123c;
  --td-light: #44403c;
  --td-dark: #27272a;
  --td-dark-01: #a83d42;
  --td-dark-02: #a8a8ad7b;
  --td-dark-03: #7a7a7bf3;

  --td-font-regular: "rubik-lighter";
  --td-font-bold: "";
  --td-font-light: "";
  --td-font-italic: "";

  --td-border-radius: 5px;
  --td-border-radius_v1: 100px;
  --td-padding-main: 20px;

  --td-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-5: 10px 20px 25px 5px rgba(0, 0, 0, 0.1),
    10px 10px 10px 5px rgba(0, 0, 0, 0.04);

  /* custom for seed */
  --td-custom-bg-footer: #98979719;
}
html {
  font-size: 14px;
  font-family: var(--td-font-regular), "Courier New", Courier, monospace;
  background-color: var(--td-white);
  box-sizing: border-box;
}
body {
  background-color: var(--td-white);
  color: var(--td-light);
  box-sizing: border-box;
}

.anton {
  font-family: "Anton";
}
.lobster-gras {
  font-family: "Lobster-gras";
}
.rubik-gras {
  font-family: "rubik-gras";
}
.rubik-lighter {
  font-family: "rubik-lighter";
}
img {
  width: 100%;
  height: 100%;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(18, 18, 192, 0.801) white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.1px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--td-primary);
  border-radius: 20px;
  border: 3px solid white;
}

.empty-data {
  color: var(--td-danger);
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 50px 0px;
  /* border: 1px solid red; */
}
/* STATUS */
.status {
  border-radius: 5px;
  font-weight: bolder;
  color: var(--td-white);
  padding: 5px 10px;
  font-size: 0.7rem;
  text-transform: uppercase;
}
.status span {
  border: 1px solid red;
}
.status.success {
  background-color: var(--td-success);
}
.status.danger {
  background-color: var(--td-danger);
}
.status.warning {
  background-color: var(--td-warning);
}
.status.info {
  background-color: var(--td-info);
}
/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}

/* #### IMPORT CSS COMPONENT FILES ###### */
@import url("site.css");
@import url("typographie.css");
@import url("animation.css");
@import url("skeleton.css");
@import url("table.css");
