.site-sous-title-h2 {
  font-family: "Anton";
  color: var(--td-secondary);
}
.site-sous-title-h2 span {
  color: var(--td-red);
}

.site-sous-title-h3 {
  font-family: "Anton";
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

a.btn-page {
  padding: 1rem;
  font-size: 0.9rem;
  font-weight: bolder;
  border-radius: 0.5rem;
}
.btn-page.bg-full {
  background-color: var(--td-secondary);
  color: var(--td-white);
}
.btn-page.bg-light {
  background-color: transparent;
  color: var(--td-white);
  border: 2px solid var(--td-white);
}
.btn-page.bg-light.primary {
  background-color: transparent;
  color: var(--td-primary);
  border: 2px solid var(--td-primary);
}

.sociaux-media {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border: 1px solid blue; */
}
.sociaux-media a {
  background-color: var(--td-primary);
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}
.sociaux-media a span {
  margin: 0;
  padding: 0;
}
.sociaux-media a:hover {
  background-color: var(--td-secondary);
}
.container-site a {
  color: var(--td-primary);
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
