/* ############## TYPOGRAPHIE ############# */
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.4rem;
}
h5 {
  font-size: 1.2rem;
}
h6 {
  font-size: 1rem;
}
p {
  line-height: 1.4rem;
}

.text {
}

/* TEXT COLORSS */
.text.danger {
  color: var(--td-danger);
}
.text.success {
  color: var(--td-success);
}
.text.warning {
  color: var(--td-warning);
}
.text.info {
  color: var(--td-info);
}

/* TEXT-FONT-WEIGHT */
.text.bold {
  font-weight: bolder;
}
.text.lighter {
  font-weight: lighter;
}
