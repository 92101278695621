.contact-nous {
  position: relative;
  background-image: url("../../../../assets/images/contact.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  position: relative;
}
.contact-nous .conctact-nous-bg-color {
  /* display: flex; */
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.821);
}
.contact-nous .conctact-nous-bg-color .contact-empty {
  flex: 2;
}
.contact-nous .conctact-nous-bg-color .contact-form {
  flex: 1;
  background-color: var(--td-white);
  padding: 2rem;
}
.contact-nous .conctact-nous-bg-color .contact-form p {
  font-size: 0.8rem;
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
  .contact-nous .conctact-nous-bg-color {
    display: flex;
    padding: 5rem;
  }
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
