.tab-action {
  display: flex;
  align-items: center;
  padding: 0.3rem 1rem;
}
.tab-action span {
  margin-left: 0.4rem;
}
.tab-action a {
  background-color: red;
  display: flex;
}
