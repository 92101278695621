.container-app {
  box-sizing: content-box;
}
.container-app .navigation {
  width: 250px;
  min-height: calc(100vh - 20px);
  height: 100%;
  position: fixed;
  color: var(--td-white);
  /* border-top-left-radius: 80px;
  border-bottom-right-radius: 80px; */
  background-color: var(--td-white);
  overflow: hidden;
  /* border: 1px solid white; */
}
.container-app .content-main {
  /* background-color: yellow; */
  width: calc(100% - 250px);
  float: right;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.container-app .content-main main {
  /* background-color: blue; */
  flex: 1;
  padding: var(--td-padding-main);
}

/* .content-main .main-container-app {
}
.content-main .main-container-app > div:nth-child(1) {
} */
.content-main .main-container-app > div:nth-child(2) {
  /* border: 1px solid blue; */
  /* display: none; */
  animation: main-enter-bottom-to-top 1s;
}

.content-main div.hidden {
  display: none;
}
.content-main div.shown {
  display: block;
}

/* ##################################### */
/* ##################################### */
/* ##################################### */
/* ######## CONTAINER STYLE V1 ############# */
/* ##################################### */
/* ##################################### */
/* ##################################### */
.v1 .container-app .navigation {
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  z-index: 0;
}
.v1 .content-main .main-container-app {
  z-index: 0;
  margin: 0px 20px;
  margin-top: -50px;
  background-color: var(--td-white);
  border-top-left-radius: var(--td-border-radius);
  border-top-right-radius: var(--td-border-radius);
}
