nav.menu-site {
  /* border: 1px solid var(--td-white); */
  padding: 1.7rem;
  background-color: var(--td-secondary);
  padding-right: 5rem;
}
.menu-site ul.web {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
}

.menu-site ul li {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}
.menu-site ul.mob li {
  padding: 0.6rem 0px;
}
.menu-site ul li span.ant-dropdown-trigger,
.menu-site ul li .site-link a {
  color: var(--td-white);
  /* font-weight: bolder; */
  font-size: 0.9rem;
  text-transform: uppercase;
  transition: all 1s;
  cursor: pointer;
}
.menu-site ul li span.ant-dropdown-trigger:hover,
.menu-site ul li .site-link a:hover {
  color: var(--td-dark-02);
  transition: all 1s;
  font-size: 0.9rem;
}
a.site-link-dropdown {
  display: flex;
  color: var(--td-primary);
  padding: 0.8rem 1.2rem !important;
  text-transform: uppercase;
  /* font-weight: bolder; */
}

.ant-anchor-ink::before {
  display: none;
}

.mobile .menu-mobile_content {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  display: flex;
}

.mobile .menu-mobile_content > div {
  background-color: var(--td-secondary);
  height: 100vh;
  padding: 1rem 1rem;
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
  .menu-site ul.web {
    display: flex;
  }
  .menu-site .mobile {
    display: none;
  }
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
