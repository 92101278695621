.about {
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 4rem;
}
.about .about_images {
  display: none;
  flex: 1;
  position: relative;
  /* background-color: red; */
}
.about .about_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}
.about .about_content div {
  margin-top: 2rem;
}
.about .about_images figure {
  border-radius: 0.8rem;
  overflow: hidden;
}
.about .about_images .figure01 {
  width: 20rem;
}
.about .about_images .figure02 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 0;
  right: 0;
  border: 0.7rem solid var(--td-white);
}
.about .about_images .figure03 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  bottom: 7.5rem;
  right: 1.5rem;
  border: 0.7rem solid var(--td-white);
}
.about .about-lire-plus {
  text-align: right;
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
  .about {
    width: 70vw;
  }
  .about .about_images {
    display: block;
  }
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
