.offres-emaploi {
  padding: 2rem 0px 5rem 0px;
}
.offres-emaploi .offre-listing {
  display: flex;
  flex-wrap: wrap;
  width: 90vw;
  margin: 0 auto;
  /* justify-content: space-between; */
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
  .offres-emaploi .offre-listing {
    width: 80vw;
  }
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
