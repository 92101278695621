@keyframes enter-text-left-to-right {
  0% {
    transform: translateX(-20px);
    opacity: 0.1;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes main-enter-bottom-to-top {
  0% {
    transform: translateY(8px);
    opacity: 0.4;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
