.nos-services {
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 4rem;
  position: relative;
}
.nos-services .title {
  margin: 0 auto;
  margin-bottom: 2rem;
}
.nos-services .services_card {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-3);
  padding-bottom: 0.5rem;
  border-radius: 0.4rem;
  overflow: hidden;
}
.nos-services .services_card .services_card-img {
  height: 200px;
}
.nos-services .services_card .services_card-img figure {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.nos-services .services_card .services_card-content {
  flex: 1;
  text-align: center;
  padding: 1rem 1rem;
  color: var(--td-dark-03);
}
.nos-services .services_card .nos-services_lire-plus {
  display: flex;
  justify-content: center;
}
