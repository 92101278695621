.main-site {
  flex: 1;
}
.main-site .main-pourquoi-nous {
  background-color: rgba(243, 236, 227, 0.7);
  padding: 1rem 0px;
}
.main-realisation {
  background-color: rgba(243, 236, 227, 0.7);
  padding: 1rem 0px;
}
