.site-presentation {
  height: 18rem;
  background-color: brown;
  background-image: url("../../../../assets/images/main-home.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
}

.site-presentation .site-bg-black {
  background-color: rgba(0, 0, 0, 0.621);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.site-presentation .site-bg-black .text-home {
  width: 90vw;
  text-align: center;
}
.site-presentation .site-bg-black .text-home h1 {
  color: var(--td-white);
  font-size: 1rem;
  /* text-transform: uppercase; */
}

.site-presentation .site-bg-black .text-home .btn-link {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;
}

/* OTHERS PAGES */
.site-presentation-other-page {
  height: 10rem !important;
}
.site-presentation.site-presentation-other-page .site-bg-black {
  background-color: rgba(0, 0, 0, 0.821);
}

/* MEDIA QUERY */
@media (min-width: 576px) {
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
  .site-presentation {
    height: 35rem;
  }
  .site-presentation .site-bg-black .text-home h1 {
    font-size: 2.5rem;
    /* text-transform: uppercase; */
  }
  .site-presentation .site-bg-black .text-home {
    width: 40vw;
    text-align: center;
  }
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
}
