.main-app-choose {
  background-color: var(--td-primary);
  height: 100vh;
  display: flex;
}
.main-app-choose .apps {
  flex: 1;
  background-color: var(--td-white);
  margin: 15vh 30vh;
  overflow: scroll;
  padding: 1rem;
}

.main-app-choose .apps .welcome-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0.7rem; */
  /* border: 1px solid red; */
}
.main-app-choose .apps .welcome-message figure {
  width: 5rem;
}
.main-app-choose .apps .welcome-message h1 {
  /* padding: 1rem; */
  line-height: 2rem;
  font-size: 1.2rem;
  color: var(--td-primary);
}
.main-app-choose .apps .welcome-message span {
  color: var(--td-dark-03);
}
.main-app-choose .apps .list-item-apps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.main-app-choose .apps .list-item-apps .item-app {
  box-shadow: var(--shadow-2);
  flex: 0 0 20%;
  margin: 0.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--td-white);
  color: var(--td-white);
  /* border-radius: 0.5rem; */
  border-top: 2px solid var(--td-dark-03);
}
.main-app-choose .apps .list-item-apps .item-app h2 {
  font-size: 0.8rem;
  line-height: 1.8rem;
  color: var(--td-primary);
  text-transform: uppercase;
}
.main-app-choose .apps .list-item-apps .item-app a {
  background-color: var(--td-secondary);
  color: var(--td-white);
  padding: 0.5rem 1rem;
  font-weight: bolder;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  transition: all 1s;
}
.main-app-choose .apps .list-item-apps .item-app a:hover {
  background-color: var(--td-dark-03);
  color: var(--td-white);
  transition: all 1s;
}
