.cards-jobs {
  display: flex;
  /* border: 1px solid red; */
  /* margin: 0.8rem; */
  flex: 0 0 100%;
  margin-top: 1.2rem;
  padding: 0.4rem 1rem;
  box-shadow: var(--shadow-3);
  border: 1px solid var(--td-dark-02);
  border-radius: 5px;
}
.cards-jobs .cards-jobs_logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cards-jobs .cards-jobs_content {
  padding-left: 1rem;
  /* border: 1px solid green; */
  width: 100%;
  font-size: 0.8rem;
}
.cards-jobs .cards-jobs_content .cards_align {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 0.5rem; */
}
.cards-jobs .cards-jobs_content .cards_align span {
  margin-left: 0.5rem;
}
.cards-jobs .cards-jobs_content .cards-jobs_title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* margin-bottom: 1.5rem; */
}
.cards-jobs .cards-jobs_content .cards-jobs_title h3 {
  flex: 1;
}
.cards-jobs .cards-jobs_content .btns {
  align-items: center;
}
.postuler-btn {
  background-color: var(--td-secondary);
  color: var(--td-white);
  padding: 0.8rem;
  font-weight: bolder;
  cursor: pointer;
}

/* MEDIA QUERY */
@media (min-width: 576px) {
  .cards-jobs {
    margin: 0.8rem;
    flex: 0 0 100%;
  }
}

/* MEDIUM DEVICES (TABLETTE : 768px and UP) */

@media (min-width: 768px) {
  .cards-jobs {
    flex: 0 0 50%;
  }
}

/* LARGE DEVICES (DESKTOP : 992px and UP) */

@media (min-width: 992px) {
  .cards-jobs {
    flex: 0 0 40%;
  }
}

/* EXTRA LARGE DEVICES (LARGE DESKTOP : 768px and UP) */
@media (min-width: 1200px) {
  .cards-jobs {
    flex: 0 0 30%;
  }
}
